import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select, Popup, User, Tooltip, Button, BUTTON_TYPES } from '_atoms';
import { fetchWrapper } from 'helpers/helpers';
import { useDebounceEffect } from 'helpers/useDebounceEffect';
import { getUser } from 'store/selectors/authorizeSelectors';
import { ACTIONS, ROLES, UI, useAbility } from 'permission';
import { ReactComponent as Delete } from 'dist/icons/close.svg';
import './style.scss';

export const getUsersByRole = (users, role, excludeUuids = []) =>
  users
    .filter(({ uuid, roles }) => !excludeUuids.includes(uuid) && (role ? roles.find(r => r.name === role) : true))
    .map(user => ({
      value: user.uuid,
      label: user.full_name,
      avatar: user.profile.avatar,
    }));

const getRecruiters = body =>
  fetchWrapper({
    url: 'users/',
    body,
  }).catch(() => []);

export const PopupAssignRecruiters = ({ open, onClose, onSave, currentRecruiters }) => {
  const ability = useAbility();
  const user = useSelector(getUser);

  const [recruitersList, setRecruitersList] = useState([]);
  const [asRecruitersList, setAsRecruitersList] = useState([]);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const assignRecruiterLead = ability.can(ACTIONS.READ, UI.ASSIGN_RECRUITER_LEAD);

  const showAssignToMe = useMemo(
    () =>
      currentRecruiters.find(
        ({ value, is_assigned_as_recruiter }) => value === user.uuid && !is_assigned_as_recruiter
      ) &&
      !asRecruitersList.find(({ value }) => value === user.uuid) &&
      user.active_role.name === ROLES.RECRUITER_LEAD,
    [asRecruitersList, currentRecruiters, user]
  );

  const onAssignToMe = () => {
    setAsRecruitersList([{ value: user.uuid, label: user.full_name, avatar: user.profile.avatar, isRl: true }]);
  };

  useEffect(() => {
    if (recruitersList.length || open) onSearch();
  }, [recruitersList, open]);

  const onAddApprover = value => {
    setRecruitersList(values => [value, ...values]);
  };

  const onSaveClick = () => {
    onSave(
      [...recruitersList, ...currentRecruiters].map(r => r.value),
      asRecruitersList.map(r => r.value)
    );
    setRecruitersList([]);
    setAsRecruitersList([]);
  };

  const onCloseClick = () => {
    setRecruitersList([]);
    setAsRecruitersList([]);
    onClose();
  };

  const onSearch = (search = '') => {
    setLoading(true);
    getRecruiters({
      role: assignRecruiterLead ? [ROLES.RECRUITER_LEAD, ROLES.RECRUITER] : ROLES.RECRUITER,
      limit: 25,
      search,
      exclude_uuid__in: [...recruitersList, ...currentRecruiters].map(el => el.value),
      my_recruiters_group: user.active_role.name === ROLES.RECRUITER_LEAD,
    }).then(resp => {
      const listOptions = [];
      const recruiterLeadOptions = assignRecruiterLead ? getUsersByRole(resp.results, ROLES.RECRUITER_LEAD) : [];
      const recruiterOptions = getUsersByRole(
        resp.results,
        ROLES.RECRUITER,
        recruiterLeadOptions.map(u => u.value)
      );
      if (recruiterLeadOptions.length) listOptions.push({ label: ROLES.RECRUITER_LEAD, options: recruiterLeadOptions });
      if (recruiterOptions.length) listOptions.push({ label: ROLES.RECRUITER, options: recruiterOptions });
      const newApproversOptions = assignRecruiterLead ? listOptions : getUsersByRole(resp.results);
      setOptions(newApproversOptions);
      setLoading(false);
    });
  };
  const debounceSearch = useDebounceEffect(onSearch, 500);

  const onDeleteItem = (value, isRl) => () => {
    if (isRl) {
      setAsRecruitersList(items => items.filter(item => item.value !== value));
    } else {
      setRecruitersList(items => items.filter(item => item.value !== value));
    }
  };

  return (
    <Popup
      open={open}
      title="Assign recruiters"
      cancelButtonProps={{
        children: 'cancel',
        onClick: onCloseClick,
      }}
      okButtonProps={{
        children: 'assign',
        onClick: onSaveClick,
        disabled: !recruitersList.length && !asRecruitersList.length,
      }}
      className="edit-approvers-popup mobile-full-size"
    >
      <Select
        placeholder="Type name"
        className="edit-approvers__input"
        value={null}
        onSearch={debounceSearch}
        options={options}
        filterOption={false}
        onChange={onAddApprover}
        loading={loading}
      />
      {showAssignToMe && (
        <Button className="edit-approvers-popup__assign-to-me" type={BUTTON_TYPES.TEXT} onClick={onAssignToMe}>
          Assign myself
        </Button>
      )}
      <div className="edit-approvers__list">
        {[...asRecruitersList, ...recruitersList].map(r => (
          <div key={r.value} className="approver">
            <div className="approver__info">
              <User
                avatar={r.avatar}
                fullName={
                  <>
                    {r.label}
                    {r.optional && <span className="optional-tag">(Optional)</span>}
                  </>
                }
              />
            </div>
            <div className="approver__extra">
              <Tooltip label="Remove" placement="topRight">
                <Delete onClick={onDeleteItem(r.value, r.isRl)} />
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </Popup>
  );
};
