import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { subject } from '@casl/ability';
import history from 'helpers/history';
import { Button, BUTTON_TYPES, Card } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { PopupAssignRecruiters } from '_molecules';
import { ReactComponent as Image } from 'dist/emptyStates/dashboardOpening.svg';
import { ACTIONS, Can, ROLES, SUBJECTS, UI } from 'permission';
import { JOB_STATUSES } from 'constants/job';
import { assignHrToJobs } from 'actions';
import './style.scss';
import { getUser } from '../../store/selectors/authorizeSelectors';

export const StepsEmpty = ({ showPopupUnarchive, jobStatus, recruiters, recruiterLeads }) => {
  const { id: jobId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [openAssign, setOpenAssign] = useState(false);

  const onOpenAssignPopup = () => setOpenAssign(true);
  const onCloseAssignPopup = () => setOpenAssign(false);
  const onSaveRecruiters = (list, asRecruiters) => {
    dispatch(assignHrToJobs(jobId, list, asRecruiters)).then(() => {
      setOpenAssign(false);
    });
  };

  const onCreateOpening = () => history.push(`/jobs/${jobId}/create-opening`);
  const onUnarchive = () => showPopupUnarchive(true);

  const isDraft = jobStatus === JOB_STATUSES.DRAFT;
  const isArchived = jobStatus === JOB_STATUSES.ARCHIVED;

  const accessToJob =
    user.active_role.name === ROLES.RECRUITER_LEAD ? recruiterLeads.find(r => r.uuid === user.uuid) : true;

  if (isArchived) {
    return (
      <Card className="steps-empty">
        <Image className="steps-empty__image" />
        <Can I={ACTIONS.READ} a={UI.ARCHIVE_JOB} passThrough>
          {access => (
            <>
              <Typography.Title3 className="steps-empty__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
                {access ? 'To start hiring process, the job should be unarchived' : 'No steps available'}
              </Typography.Title3>
              {access && (
                <Button className="steps-empty__button" type={BUTTON_TYPES.PRIMARY} onClick={onUnarchive}>
                  Unarchive job
                </Button>
              )}
            </>
          )}
        </Can>
      </Card>
    );
  }

  if (isDraft) {
    return (
      <Card className="steps-empty">
        <Image className="steps-empty__image" />
        <Can I={ACTIONS.CREATE} a={SUBJECTS.OPENING} passThrough>
          {access => (
            <>
              <Typography.Title3 className="steps-empty__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
                {access ? 'Create opening to start hiring process' : 'No steps available'}
              </Typography.Title3>
              <Typography.Text className="steps-empty__text">
                Steps will be displayed as soon as the first candidate is assigned to the job
              </Typography.Text>
              {access && (
                <Button className="steps-empty__button" type={BUTTON_TYPES.PRIMARY} onClick={onCreateOpening}>
                  Create opening
                </Button>
              )}
            </>
          )}
        </Can>
      </Card>
    );
  }

  return (
    <Card className="steps-empty">
      <Image className="steps-empty__image" />
      <Typography.Title3 className="steps-empty__title" weight={TYPOGRAPHY_WEIGHT.BOLD}>
        No steps available
      </Typography.Title3>
      <Typography.Text className="steps-empty__text">
        Steps will be displayed as soon as the first candidate is assigned to the job
      </Typography.Text>
      <Can I={ACTIONS.READ} a={subject(UI.ASSIGN_RECRUITER, { status: jobStatus })} passThrough>
        {access =>
          access &&
          accessToJob && (
            <Button className="steps-empty__button" type={BUTTON_TYPES.PRIMARY} onClick={onOpenAssignPopup}>
              assign recruiters
            </Button>
          )
        }
      </Can>
      <PopupAssignRecruiters
        open={openAssign}
        onClose={onCloseAssignPopup}
        onSave={onSaveRecruiters}
        currentRecruiters={[...recruiterLeads, ...recruiters].map(r => ({ value: r.uuid }))}
      />
    </Card>
  );
};
