import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Col, Form, Select as SelectAntd } from 'antd';
import { TextArea, Popup, Select, DatePicker, Button, BUTTON_TYPES } from '_atoms';
import { Typography } from '_atoms/Typography';
import { REQUIRED_RULE } from '_organisms/InterviewFeedback/constants';
import { dateFormatter } from 'helpers/dateFormatter';
import { BUTTON_TEXT } from 'constants/text';
import { JOB_SUBMIT_OPTIONS } from 'constants/job';
import { getOpenings } from 'actions';
import { getJobProbationPeriod } from 'store/selectors/jobSelectors';
import { DECISION_OPTIONS, REJECTION_REASONS, INITIAL_VALUES } from './constants';
import './style.scss';

export const PopupSubmitOfferDecision = ({
  open,
  onClose,
  onSubmit,
  userData = {},
  isJobStarted,
  candidateLink,
  ...props
}) => {
  const {
    first_name_latin,
    last_name_latin,
    full_name_latin,
    first_name_local,
    last_name_local,
    full_name_local,
    speciality,
  } = userData;

  const { id: jobId } = useParams();
  const dispatch = useDispatch();
  const probationPeriod = useSelector(getJobProbationPeriod);

  const [form] = Form.useForm();

  const [locationOptions, setLocationOptions] = useState([]);
  const [openings, setOpenings] = useState([]);

  const onSubmitClick = ({ decision, opening, comment, office, ...values }) => {
    const positive = decision === JOB_SUBMIT_OPTIONS.APPROVE;
    const data = positive
      ? {
          opening: opening?.value || opening,
          entry_date: values.date.format('YYYY-MM-DD'),
          comment,
          positive,
          office,
        }
      : {
          comment,
          reason: values.rejection,
          positive,
          rejected_by: undefined,
        };
    onSubmit(data);
  };

  const onChangeOpening = ({ offices }, isSetFirstLocation = true) => {
    setLocationOptions(offices.map(i => ({ label: i.name, value: i.uuid })));
    if (isSetFirstLocation) form.setFieldValue('office', offices.length < 2 ? offices[0].uuid : undefined);
  };

  useEffect(() => {
    if (open) {
      dispatch(getOpenings(jobId || props.jobId, { status: 'Open' }))
        .then(ops => {
          setOpenings(ops);
        })
        .catch(() => {
          setOpenings([]);
        });
      if (probationPeriod.opening) {
        form.setFieldsValue({
          date: moment(probationPeriod.entry_date, 'YYYY-MM-DD'),
          opening: {
            value: probationPeriod.opening.uuid,
            label: `${probationPeriod.opening.level} - ${probationPeriod.opening.team_lead.full_name}`,
          },
          office: probationPeriod.location.uuid,
        });
        onChangeOpening(probationPeriod.opening, false);
      }
    } else {
      form.resetFields();
      setLocationOptions([]);
    }
  }, [open, probationPeriod]);

  return (
    <Popup
      open={open}
      className="popup-submit-offer-decision mobile-full-size"
      title="Submit decision"
      cancelButtonProps={{
        onClick: onClose,
        children: BUTTON_TEXT.CANCEL,
      }}
      okButtonProps={{
        children: BUTTON_TEXT.SUBMIT_DECISION,
        form: 'offer-decision',
        htmlType: 'submit',
      }}
    >
      <div className="user-info">
        <Typography.Text className="user-info__title">
          {full_name_latin || `${first_name_latin} ${last_name_latin}`}
        </Typography.Text>
        <Typography.Caption className="user-info__subtitle">
          {full_name_local || `${first_name_local} ${last_name_local}`}
        </Typography.Caption>
        <Typography.Caption className="user-info__subtitle">{speciality}</Typography.Caption>
        {candidateLink && (
          <a target="_blank" href={candidateLink} rel="noreferrer">
            <Button type={BUTTON_TYPES.TEXT}>view candidate</Button>
          </a>
        )}
      </div>
      <Form name="offer-decision" form={form} initialValues={INITIAL_VALUES} onFinish={onSubmitClick}>
        <Select
          label="Decision"
          placeholder="Please select decision"
          options={DECISION_OPTIONS}
          itemProps={{ name: 'decision', rules: [REQUIRED_RULE] }}
        />
        <Form.Item noStyle shouldUpdate={(p, c) => p.decision !== c.decision}>
          {({ getFieldValue }) =>
            getFieldValue('decision') === JOB_SUBMIT_OPTIONS.APPROVE && (
              <>
                <Col span={12}>
                  <DatePicker
                    label="Entry date"
                    disabled={isJobStarted}
                    itemProps={{ name: 'date', rules: [REQUIRED_RULE] }}
                  />
                </Col>
                <Select
                  label="Opening"
                  placeholder="Please select opening"
                  disabled={isJobStarted}
                  itemProps={{ name: 'opening', rules: [REQUIRED_RULE] }}
                  onChange={onChangeOpening}
                >
                  {openings.map(i => (
                    <SelectAntd.Option
                      key={i.uuid}
                      value={i.uuid}
                      label={`${i.level} - ${i.team_lead.full_name}`}
                      offices={i.offices}
                    >
                      <div className="opening-select-option">
                        <Typography.Text>{`${i.level} - ${i.team_lead.full_name}`}</Typography.Text>
                        <Typography.Caption>{`Expected date: ${dateFormatter(i.expected)}`}</Typography.Caption>
                        <Typography.Caption>{i.offices.map(o => o.name).join(', ')}</Typography.Caption>
                      </div>
                    </SelectAntd.Option>
                  ))}
                </Select>
                <Select
                  label="Location"
                  placeholder="Please select location"
                  options={locationOptions}
                  disabled={isJobStarted || !getFieldValue('opening') || locationOptions.length < 2}
                  itemProps={{ name: 'office', rules: [REQUIRED_RULE] }}
                />
              </>
            )
          }
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(p, c) => p.decision !== c.decision}>
          {({ getFieldValue }) =>
            getFieldValue('decision') === JOB_SUBMIT_OPTIONS.REJECT && (
              <Select
                label="Rejection reason"
                placeholder="Please select step reason for rejection"
                options={REJECTION_REASONS}
                itemProps={{ name: 'rejection' }}
              />
            )
          }
        </Form.Item>
        <TextArea label="Comment" maxLength={6000} placeholder="Please enter comment" itemProps={{ name: 'comment' }} />
      </Form>
    </Popup>
  );
};
