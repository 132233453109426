import React from 'react';
import { Col, Form, Row } from 'antd';
import { Button, BUTTON_TYPES, DatePicker, Input, Popup, RichArea, Typography } from '_atoms';
import { RULES } from '_organisms/Offer/constants';
import './style.scss';

const initialValues = {};

export const PopupCreateOffer = ({ open, onClose, onSave, ...props }) => (
  <Popup className="popup-create-offer mobile-full-size" open={open} title="Add offer" onClose={onClose}>
    <div className="user-info">
      <Typography.Text className="user-info__title">{props.userData.full_name_latin}</Typography.Text>
      <Typography.Caption className="user-info__subtitle">{props.userData.full_name_local}</Typography.Caption>
      <Typography.Caption className="user-info__subtitle">{props.userData.speciality}</Typography.Caption>
      {props.userData.link && (
        <a target="_blank" href={props.userData.link} rel="noreferrer">
          <Button type={BUTTON_TYPES.TEXT}>view candidate</Button>
        </a>
      )}
    </div>
    <Form onFinish={onSave} initialValues={initialValues} validateTrigger="onSubmit">
      <Row gutter={12}>
        <Col lg={12} md={12} sm={12}>
          <Input
            label="Probation period"
            placeholder="Number of months"
            itemProps={{ name: 'probation_period', rules: RULES.PERIOD }}
          />
        </Col>
        <Col lg={12} md={12} sm={12}>
          <Typography.Text className="months">month(s)</Typography.Text>
        </Col>
      </Row>
      <Row gutter={12}>
        <Col lg={12} md={12} sm={12}>
          <DatePicker label="Expected answer date" itemProps={{ name: 'date', rules: RULES.DATE }} />
        </Col>
      </Row>
      <Form.Item name="comment">
        <RichArea label="Comment" placeholder="Add a comment" initialValue={initialValues.comment} maxLength={6000} />
      </Form.Item>
      <div className="job-offer__content-footer">
        <Button onClick={onClose}>cancel</Button>
        <Button htmlType="submit" type={BUTTON_TYPES.PRIMARY}>
          add offer
        </Button>
      </div>
    </Form>
  </Popup>
);
