import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import cn from 'classnames';
import { Col, Divider, Form, Row } from 'antd';
import { DatePicker, Input, RichArea, Button, BUTTON_TYPES, RichText, User, AtsSkeleton } from '_atoms';
import { Typography, TYPOGRAPHY_WEIGHT } from '_atoms/Typography';
import { getJobOfferRecommendation } from 'store/selectors/jobSelectors';
import { ReactComponent as Arrow } from 'dist/icons/Vector1.svg';
import { RULES } from './constants';
import './style.scss';

export const Offer = ({ className, defaultOpen, offer = {}, editable, loading, onSubmit }) => {
  const [ediMode, setEditMode] = useState(false);
  const [openDetails, setOpenDetails] = useState(defaultOpen);

  const onOpenDetails = () => setOpenDetails(o => !o);
  const onEdit = () => setEditMode(true);
  const onCancelEdit = () => setEditMode(false);

  const onSubmitOffer = ({ date, probation_period, ...values }) => {
    onSubmit({
      ...values,
      expected_answer_date: date.format('YYYY-MM-DD'),
      probation_period: +probation_period,
      is_edit: ediMode,
    });
  };

  const isEmpty = !Object.keys(offer).length;
  const expected_answer_date = offer.expected_answer_date
    ? moment(offer.expected_answer_date, 'YYYY-MM-DD')
    : moment().add(1, 'week');
  const initialValues = {
    probation_period: offer.probation_period || '1',
    date: expected_answer_date,
    comment: offer.comment || '',
  };

  if (loading) {
    return (
      <div className={cn('job-offer', className)}>
        <div className="job-offer__header">
          <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Offer</Typography.Title2>
        </div>
        <AtsSkeleton height={84} />
        <Divider />
      </div>
    );
  }

  if (isEmpty && !editable) return null;

  if (isEmpty || ediMode) {
    return (
      <div className={cn('job-offer', className, { open: openDetails })}>
        <div className="job-offer__header">
          <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Offer</Typography.Title2>
          <Arrow className="open-details" onClick={onOpenDetails} />
        </div>
        <div className="job-offer__content">
          <Recommendation />
          <Form onFinish={onSubmitOffer} initialValues={initialValues} validateTrigger="onSubmit">
            <Row gutter={12}>
              <Col lg={12} md={12} sm={12}>
                <Input
                  label="Probation period"
                  placeholder="Number of months"
                  itemProps={{ name: 'probation_period', rules: RULES.PERIOD }}
                />
              </Col>
              <Col lg={12} md={12} sm={12}>
                <Typography.Text className="months">month(s)</Typography.Text>
              </Col>
            </Row>
            <Row gutter={12}>
              <Col lg={12} md={12} sm={12}>
                <DatePicker label="Expected answer date" itemProps={{ name: 'date', rules: RULES.DATE }} />
              </Col>
            </Row>
            <Form.Item name="comment">
              <RichArea label="Comment" placeholder="Add a comment" initialValue={offer.comment} maxLength={6000} />
            </Form.Item>
            <div className="job-offer__content-footer">
              {ediMode && <Button onClick={onCancelEdit}>cancel</Button>}
              <Button htmlType="submit" type={BUTTON_TYPES.PRIMARY}>
                {ediMode ? 'save changes' : 'add offer'}
              </Button>
            </div>
          </Form>
        </div>
        <Divider />
      </div>
    );
  }

  return (
    <div className={cn('job-offer', className, { open: openDetails })}>
      <div className="job-offer__header">
        <Typography.Title2 weight={TYPOGRAPHY_WEIGHT.BOLD}>Offer</Typography.Title2>
        <Arrow className="open-details" onClick={onOpenDetails} />
      </div>
      <div className="job-offer__content">
        <Recommendation />
        {editable && (
          <Button onClick={onEdit} className="edit-button">
            Edit
          </Button>
        )}
        <div className="info-block">
          <Typography.Label>Probation period</Typography.Label>
          <Typography.Text>{`${offer.probation_period} month(s)`}</Typography.Text>
        </div>
        <div className="info-block">
          <Typography.Label>Expected answer date</Typography.Label>
          <Typography.Text>{expected_answer_date?.format('MMM DD, YYYY')}</Typography.Text>
        </div>
        <div className="info-block">
          <Typography.Label>Comment</Typography.Label>
          <RichText text={offer.comment || '-'} />
        </div>
      </div>
      <Divider />
    </div>
  );
};

const Recommendation = () => {
  const data = useSelector(getJobOfferRecommendation);

  if (!data?.approver) return null;
  return (
    <div className="job-offer__recommendation">
      <Typography.Title4 weight={TYPOGRAPHY_WEIGHT.BOLD}>Management recommendation</Typography.Title4>
      <User avatar={data.approver.avatar} fullName={data.approver.full_name} roles={data.approver.roles} />
      <Typography.Label>Recommended probation period</Typography.Label>
      <Typography.Text>{data.probation_period} month(s)</Typography.Text>
      <Typography.Label>Assumed experience level</Typography.Label>
      <Typography.Text>{data.level}</Typography.Text>
      <Typography.Label>Comment</Typography.Label>
      <Typography.Text>{data.comment}</Typography.Text>
    </div>
  );
};
